<template>
  <v-row>
    <v-col cols="12 pa-3 pt-1" v-if="isDisplayMonthOrDate">
      <v-radio-group
        v-model="hasMonthOrDate"
        row
        hide-details
        class="mt-0 pt-0"
        dense>
        <v-checkbox
          v-model="inativos"
          label="Incluir Inativos"
          class="mr-2 mt-0 mb-0"
          hide-details
          dense />
        <v-radio label="Díario" :key="1" :value="1"/>
        <v-radio label="Mensal" :key="2" :value="2"/>
      </v-radio-group>
    </v-col>

    <v-col cols="6" class="center-itens-datas pa-3 pt-1 pb-1">
      <v-menu
        v-model="menuDateInicial"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
        >

          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dtInicialString"
              v-mask="['##/##/####']"
              :label="$gettext('Data Inicial')"
              @input="testeDate('inicial', dtInicialString)"
              dense
              v-on="on">
              <template v-slot:append>
                <v-icon v-on="on">
                  event
                </v-icon>
              </template>
            </v-text-field>
          </template>

        <v-date-picker
          v-model="dtInicial"
          no-title
          scrollable
          locale="pt-br"
          :type="hasMonthOrDate === 1 ? 'date' : 'month'"
          @input="closeModalDatePicker('inicial', dtInicial)"
          :color="variables.colorPrimary">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="clearDateFilter('inicial', dtInicial)">Limpar</v-btn>
            <v-btn text color="primary" @click="closeModalDatePicker('inicial', dtInicial)">OK</v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="6" class="center-itens-datas pa-3 pt-1 pb-1">
      <v-menu
        v-model="menuDateFinal"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px">

          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dtFinalString"
              :label="$gettext('Data Final')"
              v-mask="['##/##/####']"
              @input="testeDate('final', dtFinalString)"
              v-on="on"
              dense>
              <template v-slot:append>
                <v-icon v-on="on">
                  event
                </v-icon>
              </template>
            </v-text-field>
          </template>

        <v-date-picker
          class="range-date-picke"
          v-model="dtFinal"
          no-title
          scrollable
          locale="pt-br"
          :type="hasMonthOrDate === 1 ? 'date' : 'month'"
          @input="closeModalDatePicker('final', dtFinal)"
          :color="variables.colorPrimary">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="clearDateFilter('final', dtFinal)">Limpar</v-btn>
            <v-btn text color="primary"  @click="closeModalDatePicker('final', dtFinal)">OK</v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import { mask } from 'vue-the-mask'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'

export default {
  name: 'RangeDate',
  props: {
    // 'dtInicialProps', 'dtFinalProps', 'hasMonthOrDateProps', 'inativosProps'
    dtInicialProps: {
      type: String,
      required: true
    },

    dtFinalProps: {
      type: String,
      required: true
    },

    hasMonthOrDateProps: {
      type: Number,
      default: 1
    },

    inativosProps: {
      type: Boolean,
      default: false
    },

    isDisplayMonthOrDateProps: {
      type: Boolean,
      default: false
    }
  },
  directives: {mask},
  components: {
    // dateTimerPicker: () => import('@/views/components/dateTimePicker')
  },
  data: () => ({
    dtInicial: moment().format('YYYY-MM-DD'),
    dtFinal: moment().format('YYYY-MM-DD'),
    minHora: '00:00',
    menuDateInicial: false,
    menuDateFinal: false,
    dtInicialString: moment().format('DD/MM/YYYY'),
    dtFinalString: moment().format('DD/MM/YYYY'),
    hasMonthOrDate: 1,
    inativos: false,
    isDisplayMonthOrDate: false,

    bkpDtInicial: moment().format('YYYY-MM-DD'),
    bkpDtdtFinal: moment().format('YYYY-MM-DD'),
  }),

  mounted () {
    Events.$on('filter::clear', () => {
      this.dtInicialString = moment().format('DD/MM/YYYY')
      this.dtInicial = moment().format('YYYY-MM-DD')
      this.menuDateInicial = false
      this.dtFinalString = moment().format('DD/MM/YYYY')
      this.dtFinal = moment().format('YYYY-MM-DD')
      this.menuDateFinal = false
    })

    this.$emit('update:dtInicialProps', this.dtInicial)
    this.$emit('update:dtFinalProps', this.dtFinal)
    this.$emit('update:hasMonthOrDate', this.hasMonthOrDate)
    this.$emit('update:inativosProps', this.inativos)
    this.$emit('update:isDisplayMonthOrDateProps', this.isDisplayMonthOrDate)

    this.isDisplayMonthOrDate = this.isDisplayMonthOrDateProps
  },

  watch: {
    dtInicial (val) {
      this.$emit('update:dtInicialProps', val)
    },

    dtFinal (val) {
      this.$emit('update:dtFinalProps', val)
    },

    hasMonthOrDate (val) {
      if (val === 2) {
        this.bkpDtInicial = this.dtInicial
        this.bkpDtdtFinal = this.dtFinal
      } else {
        this.dtInicial = this.bkpDtInicial
        this.dtFinal = this.bkpDtdtFinal
      }

      this.$emit('update:hasMonthOrDateProps', val)
    },

    inativos (val) {
      this.$emit('update:inativosProps', val)
    },

    isDisplayMonthOrDate (val) {
      this.$emit('update:isDisplayMonthOrDateProps', val)
    }
  },

  computed: {
    variables: () => variables,
  },

  methods: {
    clearDateFilter (type) {
      switch (type) {
        case 'inicial':
          this.dtInicialString = ''
          this.dtInicial = ''
          this.menuDateInicial = false
          break
        case 'final':
          this.dtFinalString = ''
          this.dtFinal = ''
          this.menuDateFinal = false
          break
      }
    },

    testeDate (dt, dtString) {
      const testeDate =  moment(dtString, 'DD/MM/YYYY', true)
      if (testeDate.isValid()) {
        switch (dt) {
          case 'inicial':
            if (this.hasMonthOrDate === 2) {
              this.dtInicial = moment(dtString, 'DD/MM/YYYY').format('YYYY-MM-DD')
            } else {
              this.dtInicial = moment(dtString, 'DD/MM/YYYY').format('YYYY-MM-DD')
            }
            return
          case 'final':
            if (this.hasMonthOrDate === 2) {
              this.dtFinal = moment(dtString, 'DD/MM/YYYY').format('YYYY-MM-DD')
            } else {
              this.dtFinal = moment(dtString, 'DD/MM/YYYY').endOf('month').format('YYYY-MM-DD')
            }
            return
        }
      }

    },

    allowedDates (val) {
      let atual = moment(this.dtInicial).add(-1, 'days')
      return atual.isBefore(val)
    },

    closeModalDatePicker (dt, date) {
       switch (dt) {
          case 'inicial':
            this.dtInicialString = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
            this.menuDateInicial = false
            return
          case 'final':
            if (this.hasMonthOrDate === 2) {
              this.dtFinalString = moment(date, 'YYYY-MM-DD').endOf('month').format('DD/MM/YYYY')
            } else {
              this.dtFinalString = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
            }
            this.menuDateFinal = false
            return
        }
    },

    compararDataEnd (val) {
      let inicial = moment(this.dtInicial).format('YYYYY-MM-DD')
      let dtIni = moment(inicial, 'YYYYY-MM-DD')
      this.dtFinal = moment(val).toDate()
      if (dtIni.isSame(moment(val, 'YYYYY-MM-DD')) ) {
        this.minHora = moment(this.dtInicial).add(5, 'minutes').format('H:mm')
        return ''
      }
      this.minHora = '00:00'
    }
  }
}
</script>

<style lang="scss" scoped>
  .range-date-picke::v-deep {
    .v-picker__body {
      max-width: 100%;
    }
  }
</style>